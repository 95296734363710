<template>
	<div>
  	<Breadcrumbs :items="crumbs" />
   
  	
    <div class="white">
      
      <v-container>
        <div class="display-3">
          <span class="thin">Actionable</span>
        </div><br />
        <div class="display-4">
          <span class="thick">Data</span>
        </div>
      </v-container>
    </div>

    <div class="grey darken-4 white--text">
      <v-container pt-5 pb-5>
        <v-layout row>
          <v-flex>
            <div class="headline">Collect and analyze in real time consumer reaction to your product</div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <div class="grey lighten-4">
      <v-container pt-5 pb-5>
        <v-layout row>
          <v-flex>
            <div class="title mb-1">Create dynamic campaigns based on real time trends</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">On the spot marketing before, during and post event</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Connect, engage and communicate with customers and fans in real time</div>
            <v-divider class="my-3"></v-divider>
            <div class="title mb-1">Opt-in consumer protection</div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
	</div>
</template>

<script>
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
	data() {
    return {
      name: this.$route.name,
      width: 2,
      radius: 10,
      padding: 10,
      lineCap: 'round',
      value: [1, 2, 5, 9, 5, 10, 3, 5, 2, 3, 1, 8, 2, 9, 7],
      gradientDirection: 'top',
      gradients,
      crumbs: [
        {
          text: 'Home',
          disabled: false,
          exact: true,
          to: '/'
        },
        {
          text: 'Our Technology',
          disabled: false,
          exact: true,
          to: '/technology'
        },
        {
          text: 'Data',
          disabled: true,
          to: ''
        }
      ],
    }
 	},
	components: {
    Nav,
    Footer,
    Breadcrumbs
  },
}
</script>